<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/html-self-closing -->
  <div class="w-75 mx-auto">
    <b-card>
      <b-card-body>
        <h1>{{ $t('Sell on online marketplaces') }}</h1>
        <div class="text-muted">
          {{ $t('Attract more customers by selling your products on popular marketplaces') }}
        </div>
      </b-card-body>
    </b-card>
    <b-link v-for="(item, index) in channels" v-show="item.connected" :key="index" :to="item.link">
      <b-card>
        <b-card-body>
          <div class="d-flex align-items-center">
            <b-aspect aspect="16:9" class="d-flex align-items-center">
              <b-img-lazy width="150" :src="item.img" fluid class="d-block mx-auto" />
            </b-aspect>
            <div class="flex-grow-1 text-right">
              <b v-if="item.id === 4">Create livestream</b>
              <b v-else>{{ item.count }} avalable products</b>
            </div>
            <!-- <feather-icon icon="MoreVerticalIcon" class="ml-2" /> -->
          </div>
        </b-card-body>
      </b-card>
    </b-link>
    <b-card>
      <b-card-header>
        <h3 class="w-100">
          {{ $t('Discover more sales channels') }}
        </h3>
        <div class="text-muted">
          {{ $t('Add new sales channels helps you sell to new customers online') }}
        </div>
      </b-card-header>
      <b-card-body>
        <b-button
          v-b-modal.add-more-channels
          variant="primary"
          class="btn-icon rounded"
        >
          {{ $t('Add sales channel') }}
        </b-button>
      </b-card-body>
    </b-card>
    <PopupAddChannel :channels="miniList" @click="handlerChannelDetail" />
    <PopupAddChannelDetail :item="channelDetail" @click="handlerSubmit" />
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BCard,
  BCardHeader,
  BCardBody,
  BImgLazy,
  BAspect,
  BLink,
  BButton,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PopupAddChannel from './components/PopupAddChannel.vue'
import PopupAddChannelDetail from './components/PopupAddChannelDetail.vue'
// import Request from '@/plugins/service/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BImgLazy,
    BAspect,
    BLink,
    BButton,
    PopupAddChannel,
    PopupAddChannelDetail,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      channelDetail: null,
      channels: [
        {
          id: 1,
          name: 'Lazada',
          des: 'Connecting Buso and Lazada systems, helping to synchronize inventory and prices, orders between the website system on Buso and your shop on Lazada',
          img: require('@/assets/images/salesChannel/lazada.svg'),
          connected: false,
          link: 'channel/lazada',
          count: 0,
        },
        {
          id: 2,
          name: 'Shopee',
          des: 'Connecting Buso and Shopee systems, helping to synchronize inventory and prices, orders between the website system on Buso and your shop on Shopee',
          img: require('@/assets/images/salesChannel/shopee.svg'),
          connected: false,
          link: 'channel/shopee',
          count: 0,
        },
        {
          id: 3,
          name: 'Tiki',
          des: 'Connecting Buso and Tiki systems, helping to synchronize inventory and prices, orders between the website system on Buso and your shop on Tiki',
          img: require('@/assets/images/salesChannel/tiki.svg'),
          connected: false,
          link: 'channel/tiki',
          count: 0,
        },
        {
          id: 4,
          name: 'Facebook Live',
          des: 'Connecting Buso and Facebook Live systems, helping to synchronize inventory and prices, orders between the website system on Buso and your livestream on Facebook',
          img: require('@/assets/images/salesChannel/facebook.svg'),
          connected: false,
          link: '/setting-fb',
          count: 0,
        },
      ],
    }
  },
  computed: {
    miniList() {
      const temp = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.channels.length - 1; i++) {
        temp.push(this.channels[i])
      }
      return temp
    },
  },
  mounted() {
    this.loadSiteInfo()
  },
  methods: {
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res) {
          if (res.data.status) {
            if (res.data.data.access_token_lazada !== '' && res.data.data.access_token_lazada !== null && res.data.data.access_token_lazada !== undefined) {
              this.channels.find(val => val.id === 1).connected = true
            }
            if (res.data.data.shop_id_shopee !== '' && res.data.data.shop_id_shopee !== null && res.data.data.shop_id_shopee !== undefined) {
              this.channels.find(val => val.id === 2).connected = true
              // Load lấy số lượng sản phẩm đƯợc sync từ shopee
              const respon = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/shopee/get_item_list?site_id=${JSON.parse(localStorage.getItem('siteID')).id}`)
              if (respon.status === 200) {
                if (respon.data.status === true) {
                  this.channels.find(val => val.id === 2).count = respon.data.data.length
                }
              }
            }
            if (res.data.data.token_live_facebook !== '' && res.data.data.token_live_facebook !== null && res.data.data.token_live_facebook !== undefined) {
              this.channels.find(val => val.id === 4).connected = true
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    handlerChannelDetail(data) {
      this.channelDetail = data
    },
    async handlerSubmit(data) {
      if (data.id === 1) {
        try {
          const res = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/lazada/auth_partner?mode=sandbox&path=${window.location.origin}/channel/lazada`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              window.location.href = res.data.data.link
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
      if (data.id === 2) {
        try {
          const res = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/shopee/auth_partner?mode=production&path=${window.location.origin}/channel/shopee`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              window.location.href = res.data.data.link
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
      if (data.id === 4) {
        try {
          const res = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/facebook/get_oauth?type=1&redirect_uri=${window.location.origin}/channel/facebook-live`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              window.location.href = res.data.data.url
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
    },
  },
//   async mounted() {},
//   methods: {},
}
</script>

<style lang="scss" scoped>
// @import '@core/scss/vue/libs/vue-select.scss';
</style>
