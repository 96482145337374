<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-modal
    v-if="item && item.name !== undefined"
    id="add-more-channels-detail"
    centered
    size="lg"
    :title="`${item.name} sales channel`"
    ok-title="Add channel"
    cancel-title="Back"
    @ok="handlerSubmit(item)"
  >
    <div class="d-flex align-items-center">
      <b-aspect aspect="16:9" class="d-flex align-items-center" style="min-width: 120px; max-width: 120px;">
        <b-img-lazy :src="item.img" fluid class="d-block mx-auto" />
      </b-aspect>
      <div class="flex-grow-1 mx-2">
        <b class="mb-1 d-block">{{ item.name }}</b>
      </div>
      <div><span class="text-success">Free to add</span></div>
    </div>
    <div>
      {{ item.des }}
    </div>
    <!-- <div class="my-2">
      <b>Sell on Lazada by listing your products:</b>
    </div>
    <ul>
      <li>Sell more products with Lazada</li>
      <li>Reach more customer with 150 millions active users</li>
      <li>Management your products easily</li>
    </ul> -->
    <b-aspect aspect="16:9" class="d-flex align-items-center bg-gray">
      <b-img-lazy :src="item.img" fluid class="d-block mx-auto" />
    </b-aspect>
  </b-modal>
</template>
<script>
/* eslint-disable global-require */
import {
  BImgLazy,
  BAspect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BImgLazy,
    BAspect,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    handlerSubmit(item) {
      this.$emit('click', item)
    },
  },
}
</script>
<style lang="scss" scoped>
.bg-gray {
  background: #f6f6f6;
}
</style>
