<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-modal
    id="add-more-channels"
    centered
    size="lg"
    title="Add more channels"
    :hide-footer="true"
    no-stacking
  >
    <b-card v-for="(item, index) in channels" v-show="!item.connected" :key="index">
      <b-card-body>
        <div class="d-flex align-items-center">
          <b-aspect aspect="16:9" class="d-flex align-items-center" style="min-width: 120px; max-width: 120px;">
            <b-img-lazy :src="item.img" fluid class="d-block mx-auto" />
          </b-aspect>
          <div class="flex-grow-1 mx-2">
            <b class="mb-1 d-block">{{ item.name }}</b>
            <div class="text-muted">
              {{ $t(item.des) }}
            </div>
          </div>
          <b-button
            v-b-modal.add-more-channels-detail
            variant="primary"
            class="btn-icon rounded w-50"
            @click="handlerChannelDetail(item)"
          >
            {{ $t('Learn more') }}
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </b-modal>
</template>
<script>
/* eslint-disable global-require */
import {
  BCard,
  BCardBody,
  BImgLazy,
  BAspect,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardBody,
    BImgLazy,
    BAspect,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    channels: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    handlerChannelDetail(item) {
      this.$emit('click', item)
    },
  },
}
</script>
